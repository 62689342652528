import styled from 'styled-components';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Link from '../../../../atoms/Link';

export const ItemIcon = styled(ListItemIcon)`
  && {
    min-width: initial;
    color: ${({ theme }) => theme && theme.grey[500]};
  }
`;

export const ItemText = styled(ListItemText)`
  transition: background-color 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  padding-left: 1rem;
  overflow-x: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme && theme.grey[600]};
`;

export const Item = styled(ListItem)`
  && {
    overflow-x: hidden;
    /* border-left: 3px solid white; */
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .active && {
    background-color:  ${({ theme }) => theme.primaryColor[25]};;
    border-left: 3px solid;
    padding-left: 13px;
    color: ${({ theme }) => theme.primary.main};
    border-color:  ${({ theme }) => theme.primaryColor[900]};;
    .MuiListItemIcon-root {
      color: ${({ theme }) => theme.primaryColor[900]};
    }
  }
`;

export const StyledLink = styled(Link)`
  && {
    text-decoration: none;
    /* &:hover {
      && {
        text-decoration: none;
      }
    } */
  }
`;
