/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Tooltip from '@mui/material/Tooltip';
import { ItemIcon, ItemText, Item, StyledLink } from './style';
import { GET_SIDEBAR_STATUS } from '../../../../../apollo/queries';

const SidebarItem = ({ text, icon, link, fieldsExpand, setFieldsExpand, toolTipText }) => {
  const { client } = useQuery(GET_SIDEBAR_STATUS);
  const toggleSidebar = () => {
    client.writeQuery({
      query: GET_SIDEBAR_STATUS,
      data: { sidebarOpen: false },
    });
    setFieldsExpand({
      events: false,
      subbmisions: false,
      reviews: false,
      organisations: false,
      explore: false,
      paymentMethods: false,
    });
  };
  return (
    <StyledLink href={link} onClick={toggleSidebar}>
      <Tooltip title={toolTipText} placement="right">
        <Item button key={text}>
          <ItemIcon>{icon}</ItemIcon>
          <ItemText primary={text} />
        </Item>
      </Tooltip>
    </StyledLink>
  );
};

SidebarItem.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};


export default SidebarItem;
