/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useQuery } from '@apollo/client';
import { GET_SIDEBAR_STATUS } from '@app/apollo/queries';
import { StyledDrawer, ItemText, Item, ItemIcon } from './style';
// import { organisationList } from './Lists';
import Lists from './Lists';
import { USER_ROLES } from '../../../../config/constants';

const Sidebar = ({ isOpen, userInfo }) => {
  const { data: { sidebarOpen } = { sidebarOpen: false }, client } = useQuery(GET_SIDEBAR_STATUS);

  const [fieldsExpand, setFieldsExpand] = React.useState({
    events: false,
    subbmisions: false,
    reviews: false,
    organizations: false,
    explore: false,
    paymentMethods: false,
  });

  React.useEffect(() => {
    if (!isOpen) {
      setFieldsExpand({
        events: false,
        subbmisions: false,
        reviews: false,
        organizations: false,
        explore: false,
        paymentMethods: false,
      });
    }
  }, [isOpen]);

  const toggleSidebar = () => {
    client.writeQuery({
      query: GET_SIDEBAR_STATUS,
      data: { sidebarOpen: !sidebarOpen },
    });
  };

  return (
    <StyledDrawer variant="permanent" open={isOpen}>
      {userInfo && userInfo.role.name !== USER_ROLES.USER && (
        <Lists.organisationList fieldsExpand={fieldsExpand} setFieldsExpand={setFieldsExpand} sidebarOpen={isOpen} />
      )}
      {userInfo && userInfo.role && (
        <Lists.GeneralList
          role={userInfo.role.name}
          hasReviews={userInfo.has_reviews}
          fieldsExpand={fieldsExpand}
          setFieldsExpand={setFieldsExpand}
          sidebarOpen={isOpen}
        />
      )}
      <Divider />
      <Item button onClick={toggleSidebar}>
        <ItemIcon>
          {isOpen ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
        </ItemIcon>
        <ItemText primary="Collapse" />
      </Item>
    </StyledDrawer>
  );
};

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  userInfo: PropTypes.object.isRequired,
};

Sidebar.defaultProps = {
  isOpen: false,
};

export default Sidebar;
