/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { ItemIcon, ItemText, Item, StyledLink } from './style';
import { GET_SIDEBAR_STATUS } from '../../../../../apollo/queries';

const ExpandSidebarItem = ({ text, icon, link, setFieldsExpand }) => {
  const { client } = useQuery(GET_SIDEBAR_STATUS);

  const toggleSidebar = () => {

    setFieldsExpand({
      events: false,
      subbmisions: false,
      reviews: false,
      organizations: false,
      explore: false,
      paymentMethods: false
    })
    setTimeout(() => {
      client.writeQuery({
        query: GET_SIDEBAR_STATUS,
        data: { sidebarOpen: false },
      });
    }, 150)
  };

  return (
    <StyledLink href={link} onClick={toggleSidebar}>
      <Item button key={text}>
        <ItemIcon>{icon}</ItemIcon>
        <ItemText primary={text} />
      </Item>
    </StyledLink>
  );
};

ExpandSidebarItem.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  setFieldsExpand: PropTypes.func.isRequired,
};

export default ExpandSidebarItem;
