// import { v4 as uuidv4 } from 'uuid';

export const USER_ROLES = {
  USER: 'user',
  ADMIN: 'admin',
};

export const APPLICATIONS_STATUS = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
};

export const APPLICATION_TYPE = {
  SOLO: 'SOLO',
  BAND: 'BAND',
};

export const SENIORITY_LEVELS = [
    { name: 'Masters students and research interns'},
  { name: 'PhD students'},
  { name: 'Postdoc' },
  { name: 'Senior Research/University Professors' },
  { name: 'Research Directors'},
];

// export const FIELDS = {
//   BAND_FIRSTNAME: 'd55bb9fa-ad6b-4877-a8c7-792798a83480',
//   BAND_LASTNAME: '28141256-de30-4b0f-872c-d00947139743',
//   BAND_EMAIL: '99e7aa82-5577-439a-bf04-32db4579a7ab',
//   BAND_INSTRUMENTS: '81efb30a-b860-4cc3-ba69-9c711af41387',
//   BAND_PHONE: '73dffdea-e86a-4db5-a838-ed3c1b2ce477',
//   BAND_BIRTH: 'bc823a2a-70c2-4325-a9d8-4a4790025d5e',
//   BAND_GENDER: '73095c60-b63a-496e-8d59-7afffaa013ab',
//   BAND_BACK_VOCAL: 'a05064c7-5303-4b0b-9cf6-a6a1790b8a66',
// };
