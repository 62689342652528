import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Grid from '@mui/material/Grid';
import { useQuery } from '@apollo/client';

import VerficationAlert from '@molecules/common/VerficationAlert';
import Header from '@organisms/common/Header';
import Sidebar from '@organisms/common/Sidebar';

import { GET_SIDEBAR_STATUS } from '@app/apollo/queries';
import { useUser } from '@app/helpers/hooks';

import { BoxContianer, LayoutContainer, ContentContainer, VerficationContainer } from './style';


const HeaderWithSidebar = ({ children, title, contentPadding }) => {
  const { data: { sidebarOpen } = { sidebarOpen: false }, client } = useQuery(GET_SIDEBAR_STATUS);

  const { user } = useUser();

  const toggleSidebar = () => {
    client.writeQuery({
      query: GET_SIDEBAR_STATUS,
      data: { sidebarOpen: !sidebarOpen },
    });
  };

  return (
    <>
      <Head>
        <title>{`Scivenia ${title}`}</title>
      </Head>
      <LayoutContainer>
        <Header sidebar={!!user } onClick={toggleSidebar} />
        <>
          <ContentContainer>
            {user && <Sidebar isOpen={sidebarOpen} userInfo={user} />}
            <BoxContianer contentPadding={contentPadding} isOpen={sidebarOpen} hasSidebar={!!user}>
              <LayoutContainer>
                  { user && !user.is_verified ? (
                    <VerficationContainer>
                      <VerficationAlert />
                    </VerficationContainer>
                  ) 
                  : <VerficationContainer/>
                }
                <Grid item xs={12}>
                  {children}
                </Grid>
              </LayoutContainer>
            </BoxContianer>
          </ContentContainer>
        </>

        {/* <Footer /> */}
      </LayoutContainer>
    </>
  );
};

HeaderWithSidebar.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  contentPadding: PropTypes.number,
};

HeaderWithSidebar.defaultProps = {
  title: '',
  contentPadding: 2,
};

export default HeaderWithSidebar;
