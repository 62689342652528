import styled, { css } from 'styled-components';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { deviceSizes } from '../../../../config/devices'
// import { ExpandItem } from './ExpandSidebarItem/style';

export const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    margin-top: 64px;
    width: ${({ open }) => (open ? '275px' : '60px')};
    display: flex;
    overflow-x: hidden;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    /* position: inherit; */
  }

  @media (max-width: ${deviceSizes.sm}) {
    .MuiPaper-root {
      width: ${({ open }) => (open ? '100%' : '0')};
    }
  }
`;

export const MenuButton = styled(IconButton)`
  width: 60px;
`;

export const ItemIcon = styled(ListItemIcon)`
  && {
    min-width: initial;
    color: ${({ theme }) => theme && theme.grey[500]};
  }
`;

export const ItemText = styled(ListItemText)`
  transition: background-color 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  padding-left: 1rem;
  overflow-x: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme && theme.grey[600]};
`;

export const Item = styled(ListItem)`
  && {
    overflow-x: hidden;
    /* border-left: 3px solid white; */
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .active && {
    background-color: rgba(243, 82, 82, 0.08);
    border-left: 3px solid;
    padding-left: 13px;
    color: ${({ theme }) => theme.primary.main};
    .MuiListItemIcon-root {
      color: ${({ theme }) => theme.primary.main};
    }
    .MuiListItemText-primary {
      color: ${({ theme }) => theme.primary.main};
    }
  }
`;

export const StyledAccordion = styled(Accordion)`
  && {
    margin: 0;
    overflow-x: hidden;
    box-shadow: none;
    &:hover {
      && {
        background-color: ${({ theme }) => theme.secondary.light};
      }
    }
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    padding: 0;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    overflow-x: hidden;
    /* border-left: 3px solid white; */
    padding-top: 4px;
    padding-bottom: 4px;
    justify-content: flex-start;
    white-space: nowrap;
    ${({ isActive }) =>
    isActive &&
    css`
        background-color: ${({ theme }) => theme.primaryColor[25]};
        border-left: 3px solid;
        padding-left: 13px;
        border-color: ${({ theme }) => theme.primaryColor[900]};
  `}
  }
  .MuiSvgIcon-root {
    ${({ isActive }) =>
    isActive &&
    css`
        color: ${({ theme }) => theme.primaryColor[900]};;
    `}
  }
  /* .MuiTypography-root {
    ${({ isActive }) =>
    isActive &&
    css`
        color: ${({ theme }) => theme.blue[800]};
    `}
  } */
`;
